
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination } from "@/libs/utils";
import { Bank } from "@/store/bank/types";

const VBankList = defineComponent({
  name: "VBankList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de bancos | obmed" });

    const store = useStore();
    const route = useRoute();
    const { hasFilters } = useFilter();

    const banks = computed(() => store.state.bank.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(bank: Bank) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "bank-edit", params: { _id: String(bank.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "bank-edit", params: { _id: String(bank.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "bank-delete", params: { _id: String(bank.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Bancos",
        icon: "fas fa-piggy-bank mr-1",
        to: { name: "bank-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "bank",
      setPage: "setBankPaginationPage",
      updateList: getBanks,
    });

    async function getBanks() {
      loading.list = true;
      await store.dispatch("getBanks");
      loading.list = false;
    }

    getBanks();

    return {
      banks,
      pagination,
      loading,
      breadcrumb,
      hasFilters,
      getDropdownOptions,
      handleUpdateCurrentPage,
      getBanks,
    };
  },
});

export default VBankList;
